(function ($) {
    'use strict';

    $.animation = new Object({
        tolerance: {
            up: 70,
            down: 0,
        },
        threshold: 0.1,

        init: function () {
            var observer = new IntersectionObserver(
                function (observables) {
                    for (var i in observables) {
                        if (observables[i].isIntersecting) {
                            var animationClassname = observables[i].target.dataset.animation;
                            if (animationClassname) {
                                observables[i].target.classList.add(animationClassname);
                            }
                            observer.unobserve(observables[i].target);
                        }
                    }
                },
                {
                    threshold: this.threshold,
                    rootMargin: '-' + this.tolerance.up + 'px 0px -' + this.tolerance.down + 'px',
                },
            );

            $('[data-animation]').each(function (_, element) {
                observer.observe(element);
            });
        },
    });
})(jQuery);
