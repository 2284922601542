(function ($) {
    var _defaultConfig = {
        itemSelector: '.highlight-slider--item',
        activeItemClass: 'highlight-slider--item__active',
        startItemClass: 'highlight-slider--item__start',
        endItemClass: 'highlight-slider--item__end',
        itemWrapperClass: 'highlight-slider--content-wrapper',
        itemLeadSelector: '.highlight-slider--lead',
        itemDescriptionToggleSelector: '.highlight-slider--description-toggle',
        itemDescriptionSelector: 'highlight-slider--description',
        itemCollapseSelector: '.highlight-slider--content',
        collapseBreakpoint: '768px',
        intervalTimeout: 5000,
        arrowPrevSelector: '[data-function="prev"]',
        arrowNextSelector: '[data-function="next"]',
    };

    $.fn.highlightSlider = function (userConfig) {
        const config = $.extend({}, _defaultConfig, userConfig);
        const items = $(this).children(config.itemSelector);
        const container = this;
        let isMobile = false;
        // let interval = null;
        // const observer = null;

        function getActiveItem() {
            return items.filter('.' + config.activeItemClass);
        }

        function hide(item, reverse) {
            if (!item.hasClass(config.activeItemClass)) {
                return;
            }

            if (isMobile) {
                var content = item.children(config.itemCollapseSelector);
                var scrollHeight = content.prop('scrollHeight');

                content.css('height', scrollHeight + 'px');
                item.removeClass(config.activeItemClass);
                requestAnimationFrame(function () {
                    content.removeAttr('style');
                });
            } else {
                var className = reverse ? config.startItemClass : config.endItemClass;

                item.addClass(className);
                item.on('transitionend', function onHighlightSliderItemTransitionEnd(e) {
                    if ($(e.target).hasClass(config.itemWrapperClass)) {
                        item.off('transitionend', onHighlightSliderItemTransitionEnd)
                            .removeClass(config.activeItemClass)
                            .removeClass(className);
                    }
                });
            }
        }

        function show(item, reverse) {
            if (item.hasClass(config.activeItemClass)) {
                return;
            }

            if (isMobile) {
                var content = item.children(config.itemCollapseSelector);
                var scrollHeight = content.prop('scrollHeight');

                item.addClass(config.activeItemClass);
                content.css('height', scrollHeight + 'px');
                content.on('transitionend', function onHighlightSliderItemTransitionEnd(e) {
                    if ($(e.target).hasClass(config.itemWrapperClass)) {
                        content.off('transitionend', onHighlightSliderItemTransitionEnd).removeAttr('style');
                    }
                });
            } else {
                var className = reverse ? config.endItemClass : config.startItemClass;

                item.addClass(className).addClass(config.activeItemClass);
                requestAnimationFrame(function () {
                    item.removeClass(className);
                });
            }
        }

        function select(item, reverse) {
            if (item.hasClass(config.activeItemClass)) {
                return;
            }

            requestAnimationFrame(function () {
                getActiveItem().each(function () {
                    hide($(this), reverse);
                });
                show(item, reverse);
                setInert();
            });
        }

        function setInert() {
            $(items).each(function () {
                if (!$(this).hasClass(config.activeItemClass)) {
                    $(this).find(config.itemCollapseSelector).prop('inert', true);
                } else {
                    $(this).find(config.itemCollapseSelector).removeAttr('inert');
                }
            });
        }

        // function next() {
        //     var nextItem = getActiveItem().next(config.itemSelector);
        //     if (nextItem.length === 0) {
        //         nextItem = items.first();
        //     }
        //     select(nextItem);
        // }

        // function prev() {
        //     var prevItem = getActiveItem().prev(config.itemSelector);
        //     if (prevItem.length === 0) {
        //         prevItem = items.last();
        //     }
        //     select(prevItem, true);
        // }

        // function stopInterval() {
        //     clearInterval(interval);
        // }
        //
        // function startInterval() {
        //     clearInterval(interval);
        //     interval = setInterval(intervalCallback, config.intervalTimeout);
        // }
        //
        // function intervalCallback() {
        //     if (!isMobile) {
        //         next();
        //     }
        // }
        //
        // function resetInterval() {
        //     stopInterval();
        //     startInterval();
        // }

        function init() {
            if (container.length === 0) {
                return;
            }

            // Setup media query listener
            var mediaQuery = window.matchMedia('(max-width: ' + config.collapseBreakpoint + ')');
            mediaQuery.addListener(function (mql) {
                isMobile = mql.matches;

                // if (!isMobile) {
                //     resetInterval();
                // }
            });
            isMobile = mediaQuery.matches;

            // Make item heading clickable
            $(items)
                .children(config.itemLeadSelector)
                .click(function () {
                    // resetInterval();

                    var targetItem = $(this).parent(config.itemSelector);
                    var isTargetBeforeActiveElement = targetItem.nextAll('.' + config.activeItemClass).length > 0;

                    select(targetItem, isTargetBeforeActiveElement);
                });

            // toggle infobox via button
            if (!isMobile) {
                $(items)
                    .find(config.itemDescriptionToggleSelector)
                    .each(function () {
                        $(this).on('click', function () {
                            if ($(this).next().hasClass(config.itemDescriptionSelector)) {
                                $(this).next().toggleClass('open');
                            }
                        });
                    });

                setInert();
            }

            // Start automatic slide
            // if ('IntersectionObserver' in window) {
            //     observer = new IntersectionObserver(function (entries) {
            //         if (entries[0].isIntersecting) {
            //             startInterval();
            //         } else {
            //             stopInterval();
            //         }
            //     });
            //     observer.observe($(container).get()[0]);
            // } else {
            //     startInterval();
            // }

            // Add arrow functionality
            // $(config.arrowPrevSelector).click(function (e) {
            //     e.preventDefault();
            // resetInterval();
            //     prev();
            // });
            // $(config.arrowNextSelector).click(function (e) {
            //     e.preventDefault();
            // resetInterval();
            //     next();
            // });

            // Stop autoplay on hover
            // $(items)
            //     .on('mouseenter', function () {
            //         stopInterval();
            //     })
            //     .on('mouseleave', function () {
            //         startInterval();
            //     });
        }

        init();
    };
})(jQuery);
