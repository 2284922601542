(function ($) {
    'use strict';

    var _defaultConfig = {
        wrapperSelector: '.grid--wrapper',
        scrollSelector: '.grid--scroll',
        itemSelector: '.grid--item',
        animationSelector: '.grid--container',
        silent: false,
        hideReverse: true,
        animationStartClass: 'animation-start',
        animationStartToClass: 'animation-start-to',
        animationStartActiveClass: 'animation-start-active',
        animationLeaveClass: 'animation-leave',
        animationLeaveToClass: 'animation-leave-to',
        animationLeaveActiveClass: 'animation-leave-active',
        animationActiveState: 'animation-active-state',
    };

    /**
     * Returns childs of given elements, sorted by parents dataset attributes
     * @param {[HTMLElement]} parents
     * @param {string} childSelector
     * @returns {*}
     */
    function findAndSortElements(parents, childSelector) {
        var childs = parents.children(childSelector).get();
        var orderByAttribute = 'order';

        if (window.matchMedia('(min-width:768px)').matches) {
            if (window.matchMedia('(orientation:portrait)').matches) {
                orderByAttribute = 'portraitOrder';
            }

            childs.sort(function (prev, next) {
                var prevOrder = parseInt(prev.parentElement.dataset[orderByAttribute]);
                var nextOrder = parseInt(next.parentElement.dataset[orderByAttribute]);

                return prevOrder - nextOrder;
            });
        } else {
            childs.reverse();
        }

        return childs;
    }

    /**
     * Polyfill for Number.isNaN for IE
     * @param value Value to check
     * @return {boolean}
     */
    function _isNaN(value) {
        return Number.isNaN ? Number.isNaN(value) : window.isNaN(value);
    }

    /**
     * Converts a css time value to numeric value
     * @param {string} value
     * @return {number} Numeric value or 0 if css value couldn't be parsed
     */
    function convertCssTimeToNumber(value) {
        var numberValue = parseFloat(value) * 1000;
        if (_isNaN(numberValue)) {
            return 0;
        }
        return numberValue;
    }

    /**
     * Gets the animation duration of an element including its animation delay
     * @param {HTMLElement} element
     * @return {number} Animation duration in milliseconds
     */
    function getAnimationDelay(element) {
        var totalValues = [];
        var durations = $(element).css('transition-duration').split(',').map(convertCssTimeToNumber);
        var delays = $(element).css('transition-delay').split(',').map(convertCssTimeToNumber);

        for (var i = 0; i < Math.max(durations.length, delays.length); i++) {
            var time = 0;

            if (durations[i]) {
                time += durations[i];
            }

            if (delays[i]) {
                time += delays[i];
            }

            totalValues.push(time);
        }

        return Math.max.apply(Math, totalValues);
    }

    /**
     * Handles animation classes on an element and executes callback after finished
     * @param {HTMLElement} element
     * @param {object} config
     * @param {string} config.startClass
     * @param {string} config.toClass
     * @param {string} config.activeClass
     * @param {string} config.stateClass
     * @param {void} callback
     * @return {number} Returns calculated animation duration in milliseconds
     */
    function animate(element, config, callback) {
        requestAnimationFrame(function () {
            var end = $(element).hasClass(config.stateClass);

            $(element).addClass(config.startClass).addClass(config.stateClass);

            requestAnimationFrame(function () {
                var delay;

                $(element).addClass(config.activeClass).addClass(config.toClass).removeClass(config.startClass);

                delay = getAnimationDelay(element);

                setTimeout(function () {
                    $(element).removeClass(config.activeClass).removeClass(config.toClass);

                    if (end) {
                        $(element).removeClass(config.stateClass);
                    }

                    return callback();
                }, delay);
            });
        });
    }

    /**
     * Class that executes a set of async functions serial
     * @constructor
     */
    function Queue() {
        var _this = this;
        var _queue = [];

        function add(callback) {
            _queue.push(callback);
        }

        function _execute(index, callback) {
            if (!_queue[index]) {
                if (callback) {
                    callback();
                }
                return;
            }

            _queue[index](function () {
                return _execute(index + 1, callback);
            });
        }

        function run(callback) {
            return _execute(0, callback);
        }

        _this.add = add;
        _this.run = run;
    }

    $.fn.grid = function (userConfig) {
        var _config = $.extend({}, _defaultConfig, userConfig);
        var _isActive = false;
        var _inProgress = false;
        var _overlay = this;
        var _scroll = _overlay.children(_config.scrollSelector);
        var _container = _scroll.children(_config.wrapperSelector);
        var _items = _container.children(_config.itemSelector);
        var controller = {
            show: show,
            hide: hide,
            toggle: toggle,
        };

        function show(callback) {
            var queue = new Queue();
            var animationConfig = {
                startClass: _config.animationStartClass,
                toClass: _config.animationStartToClass,
                activeClass: _config.animationStartActiveClass,
                stateClass: _config.animationActiveState,
            };
            var elements;

            if (_isActive) {
                if (!_config.silent) {
                    throw new Error('Grid already active');
                }
                return console.error('Grid already active');
            }

            if (_inProgress) {
                if (!_config.silent) {
                    throw new Error('Grid already in progress');
                }
                return console.error('Grid already in progress');
            }

            $('body').addClass('grid__active');
            $(window).scrollTop(0);
            _overlay.show();
            _scroll.scrollTop(0);

            _inProgress = true;
            elements = findAndSortElements(_items, _config.animationSelector);

            $(elements).each(function (index, element) {
                queue.add(function (next) {
                    animate(element, animationConfig, next);
                });
            });

            queue.run(function () {
                _isActive = true;
                _inProgress = false;

                if (callback) {
                    callback();
                }
            });
        }

        function hide(callback) {
            var queue = new Queue();
            var animationConfig = {
                startClass: _config.animationLeaveClass,
                toClass: _config.animationLeaveToClass,
                activeClass: _config.animationLeaveActiveClass,
                stateClass: _config.animationActiveState,
            };
            var elements;

            if (!_isActive) {
                if (!_config.silent) {
                    throw new Error('Grid not active');
                }
                return console.error('Grid not active');
            }

            if (_inProgress) {
                if (!_config.silent) {
                    throw new Error('Grid already in progress');
                }
                return console.error('Grid already in progress');
            }

            _inProgress = true;
            elements = findAndSortElements(_items, _config.animationSelector);

            if (!_config.hideReverse) {
                elements.reverse();
            }

            $(window).scrollTop(0);
            $(elements).each(function (index, element) {
                queue.add(function (next) {
                    animate(element, animationConfig, next);
                });
            });

            queue.run(function () {
                _isActive = false;
                _inProgress = false;

                $('body').removeClass('grid__active');
                _overlay.hide();

                if (callback) {
                    callback();
                }
            });
        }

        function toggle(callback) {
            if (_inProgress) {
                var errMsg = 'Grid already in progress';
                if (!_config.silent) {
                    throw new Error(errMsg);
                }
                return console.error(errMsg);
            }

            if (_isActive) {
                return hide(callback);
            }
            return show(callback);
        }

        Object.defineProperty(controller, 'isActive', {
            get: function () {
                return _isActive;
            },
        });

        Object.defineProperty(_container, 'isInProgress', {
            get: function () {
                return _inProgress;
            },
        });

        return controller;
    };
})(jQuery);
