(function ($) {
    'use strict';

    function tstamp(add) {
        var time = Math.floor(Date.now() / 1000),
            offset = parseInt(add) || 0;

        if (offset > 0) {
            time += offset;
        }

        return time;
    }

    var cache = (function () {
        if (/PHPSESS|be_user/i.test(document.cookie + '')) {
            return { get: $.noop, set: $.noop };
        } else {
            return {
                get: function (key) {
                    try {
                        var data = JSON.parse(sessionStorage.getItem(key) || '{}');

                        if (data && data.content && data.expires) {
                            if (data.expires < tstamp()) {
                                sessionStorage.removeItem(key);
                            } else {
                                return data.content;
                            }
                        }
                    } catch (e) {}
                    return null;
                },
                set: function (key, data, ttl) {
                    var el = JSON.stringify({
                        expires: tstamp(ttl),
                        content: data,
                    });

                    sessionStorage.setItem(key, el);
                },
            };
        }
    })();

    $.promoCockpit = {
        init: function () {
            if ($('[data-xhr-content]').length > 0) {
                var el = $('[data-xhr-content]'),
                    url = el.data('xhrContent'),
                    key = url.replace(/[^A-Za-z0-9\\.]+/g, ''),
                    data = cache.get(key);

                // we migh fix this. at the moment i simply removed the data fetching from the localStorage
                // but we migh add a timestamp with the "last created/edited" entry from the colpos content elements
                // and check the lastvisit timestamp against that and loads the entrys new in the localstorag
                // but for now this might be enought. in case the website is way to slow, we can improve this
                // but the session storage livetime is only 1800seconds. so this might not be the problem at all.
                // related: https://support.sup7.at/issues/17691
                if (data) {
                    el.html(data);
                    $.promoCockpit.start();
                } else {
                    $.get(url, function (response) {
                        cache.set(key, response, 120);
                        el.html(response);
                        $.promoCockpit.start();
                    });
                }
            }
        },
        start: function () {
            if (this.checkStorage() === true) {
                var _count = 0,
                    _lastVisit = this.getLastVisit();

                $('.promo-cockpit__item').each(function () {
                    if (parseInt($(this).data('created')) > _lastVisit) {
                        _count += 1;
                    }
                });

                $('[data-notifications]').attr('data-notifications', _count);

                if (_count >= 0) {
                    $('[data-notifications]').addClass('show-notes');
                }
            }
        },

        getLastVisit: function () {
            return localStorage.getItem('lastVisit') !== null ? localStorage.getItem('lastVisit') : 0;
        },

        setLastVisit: function () {
            localStorage.setItem('lastVisit', tstamp());
            $('[data-notifications]').attr('data-notifications', 0);
        },

        checkStorage: function () {
            if (typeof Storage !== 'undefined') {
                return true;
            } else {
                return false;
            }
        },

        animate: function () {
            var that = this;
            $('[data-notifications]').on('click', function (e) {
                e.preventDefault();

                if ($('.morph').length >= 1) {
                    return void 0;
                }

                var _overlay = $('<div />').addClass('morph');

                $('body').append(_overlay);

                var _scale = that.getScale(),
                    _pos = _overlay.offset();

                _overlay.css({
                    bottom:
                        _pos.top +
                        _overlay.outerHeight() * 0.5 -
                        _overlay.outerHeight() * _scale * 0.5 -
                        $(window).scrollTop(),
                    left:
                        _pos.left +
                        _overlay.outerWidth() * 0.5 -
                        _overlay.outerWidth() * _scale * 0.5 -
                        $(window).scrollLeft(),
                    width: _overlay.outerWidth() * _scale,
                    height: _overlay.outerHeight() * _scale,
                    transform: 'scale(1)',
                });

                var t = that.whichTransitionEvent();
                t &&
                    _overlay.bind(t, function () {
                        that.openPromoCockpit();
                    });
            });
        },

        getScale: function () {
            var $btn = $('.morph'),
                radius = $btn.outerWidth() * 0.5,
                left = $btn.offset().left + radius - $(window).scrollLeft(),
                top = $btn.offset().top + radius - $(window).scrollTop(),
                windowW = $(window).width(),
                windowH = $(window).height();

            var maxDistHor = left > windowW / 2 ? left : windowW - left,
                maxDistVert = top > windowH / 2 ? top : windowH - top;

            return Math.ceil(Math.sqrt(Math.pow(maxDistHor, 2) + Math.pow(maxDistVert, 2)) / radius);
        },

        openPromoCockpit: function () {
            $('.promo-cockpit, body > footer').addClass('visible');
            $('body, html').addClass('noscroll');

            if ($('.promo-cockpit > footer').length <= 0) {
                var _footer = $('body > footer').clone();

                $('.promo-cockpit').append(_footer.addClass('d-lg-none'));
            }

            $.promoCockpit.setLastVisit();
        },

        closePromoCockpit: function () {
            $('[data-close]').on('click', function (e) {
                e.preventDefault();
                $('.promo-cockpit, body > footer').removeClass('visible');
                $('body, html').removeClass('noscroll');
                $('.morph').remove();
            });

            $(document).on('keyup', function (e) {
                if (e.keyCode === 27) {
                    $('[data-close]').trigger('click');
                }
            });
        },

        whichTransitionEvent: function () {
            var t;
            var el = document.createElement('fakeelement');
            var transitions = {
                transition: 'transitionend',
                OTransition: 'oTransitionEnd',
                MozTransition: 'transitionend',
                WebkitTransition: 'webkitTransitionEnd',
            };

            for (t in transitions) {
                if (el.style[t] !== undefined) {
                    return transitions[t];
                }
            }
        },
    };
})(jQuery);
