(function ($) {
    'use strict';

    $.sticky = new Object({
        element: $('.STICKY_PAGE_NAVIGATION'),
        scrollPosThreshold: 10,
        automaticHidingTime: 4000,

        init: function () {
            if (this.element.length > 0 && parseInt($(document).scrollTop()) === 0 && !this.element.hasClass('init')) {
                this.element.addClass('init');
            }

            this.timeOutCheck();
            this.scrollPosCheck();
        },

        timeOutCheck: function () {
            var that = this;

            setTimeout(function () {
                that.element.removeClass('init');
            }, that.automaticHidingTime);
        },

        scrollPosCheck: function () {
            var that = this;

            $(window).scroll(function () {
                var _pos = $(document).scrollTop();

                if (_pos > that.scrollPosThreshold) {
                    that.element.removeClass('init');
                } else {
                    that.element.find('.jumplink').removeClass('active');
                }
            });
        },
    });
})(jQuery);
