(function ($) {
    'use strict';

    $.tabbedContent = new Object({
        init: function () {
            this.changeTabs();
            // this.cloneContent();
        },

        cloneContent: function () {
            var _activeBreakpoint = window
                    .getComputedStyle(document.querySelector('body'), ':after')
                    .getPropertyValue('content')
                    .replace(/\"/g, ''),
                _proof = ['sm', 'xs'];

            if ($.inArray(_activeBreakpoint, _proof) >= 0) {
                $('.clone-into:not(.cloned)').each(function () {
                    var $clone = $('.tabbedContent__content-item[data-id="' + $(this).data('id') + '"]');
                    $(this).prepend($clone).addClass('cloned');
                });
            } else {
                if ($('.clone-into.cloned').length > 0) {
                    $('.tabbedContent__content').prepend($('.clone-into.cloned .tabbedContent__content-item'));
                    $('.clone-into.cloned').removeClass('cloned');
                }
            }
        },

        changeTabs: function () {
            $('.tabbedContent__list-items__button').on('click', function (e) {
                e.preventDefault();
                var _toggleThis = $('[data-panel="' + $(this).data('toggle') + '"]');

                // if ($(this).hasClass('active')) {
                //     $(this).removeClass('active');
                //     _toggleThis.removeClass('active');
                //     return void(0);
                // }

                // if (_toggleThis.is(':visible')) {
                //     _toggleThis.removeClass('active')
                //     return void(0);
                // }

                $('.tabbedContent__list-items__button').removeClass('active');
                $(this).addClass('active');

                $('.tabbedContent__content-item').removeClass('active');
                _toggleThis.addClass('active');

                // var _scrollTo = $(this).offset().top - $('header.main').outerHeight();

                // $('html, body').animate({
                //     scrollTop: _scrollTo
                // }, 0);
            });

            // $('.tabbedContent__list-item:first-child > a').addClass('active');
        },
    });
})(jQuery);
