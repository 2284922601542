(function ($) {
    'use strict';

    $.Navigation = new Object({
        init: function () {
            $('.navigation__main .navigation__link').on('click', function (e) {
                e.preventDefault();

                $('body').addClass('navigation__open');
                $('.navigation__overlay').removeClass('active');

                $(this).parent().find('.navigation__overlay').addClass('active');
            });
        },
    });
})(jQuery);
